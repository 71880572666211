@import '../Canvas/canvas.module';

.container {
  margin: auto;
}

.content {
  height: var(--canvas-height);
}

.previewBlock {
  position: absolute;
  padding: var(--canvas-gutter);
  pointer-events: none;

  > div {
    overflow: hidden;
    border-radius: var(--block-border-radius);
  }
}
