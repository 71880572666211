:root {
  --picker-size: 2rem;
}

.pickerContainer {
  display: inline-flex;
  position: relative;
  vertical-align: top;
}

.pickerOpener {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font: 1rem/1.3 var(--font-family);
  cursor: pointer;
  gap: 0.5rem;
}

.pickerOpenerIcon {
  width: var(--picker-size);
  border-radius: 0.25rem;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 25%) inset;
  aspect-ratio: 1 / 1;
}

.pickerDrop,
.pickerGroup {
  display: grid;
  position: relative;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  gap: 0.25rem;
}

.pickerDrop {
  grid-template-columns: repeat(8, 1fr);
  width: 280px;
  padding: 0.375rem;
}

.pickerCheckIcon {
  stroke-width: 2px;
  stroke: #222f3e;
  fill: #fff;
  paint-order: stroke;
}

.pickerColorItem {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.15s ease;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 25%) inset;
  cursor: pointer;
  aspect-ratio: 1 / 1;

  input {
    visibility: hidden;
    position: absolute;
    opacity: 0;
  }

  &:hover,
  &:focus {
    scale: 1.1;
  }
}
