.busStopContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.busStopContent {
  display: flex;
  position: relative;
  flex: 1;
  min-height: 0;
}

.busStopHolder {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.popover {
  :global {
    .MuiPopover-paper {
      width: 660px;
      padding: 0.75rem 1rem;
    }

    .deployed-tag {
      width: 100%;
    }
  }
}
