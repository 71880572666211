.spinner {
  --size: 32px;
  --color: 53 55 56;

  display: block;
  width: var(--size);
  height: var(--size);
  margin: auto;
  animation: spinner-grow 0.75s linear infinite;
  border-radius: 50%;
  opacity: 0;
  background: rgb(var(--color));
  vertical-align: text-bottom;

  &.static {
    display: block;
    margin: auto;
  }

  &.absolute {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    margin: calc(var(--size) / 2 * -1) 0 0 calc(var(--size) / 2 * -1);
  }
}

.small {
  --size: 22px;

  background: rgb(var(--color) / 20%);
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    transform: none;
    opacity: 1;
  }
}
