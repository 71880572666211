.formGroup {
  display: flex;
  margin: 0 0 1rem;
}

.controlLabel {
  width: 150px;
  margin: 0 1rem 0 0;
  padding: 0.35rem 0;
  color: #666;
  font-weight: 400;
}

.controlHolder {
  flex: 1;
}
