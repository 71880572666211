.pageBlock {
  margin: 0 0 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.pageHolder {
  position: relative;
  margin: 0 0 0.375rem;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 0.25rem;
  background: #e5e4e4;
  box-shadow: 0 0 0 1px #bbb;

  :global(.sortable-chosen) & {
    z-index: 5;
    box-shadow: 0 0 0 2px #666;
  }

  :global(.on-choose > div:not(.sortable-chosen)) & {
    pointer-events: none;
  }
}

.activeBlock {
  box-shadow: 0 0 0 2px var(--primary);
}

.pageHolderActions {
  display: flex;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease;
  opacity: 0;
  background: rgb(0 0 0 / 65%);
  color: #fff;

  .pageHolder:hover & {
    transform: translateY(0);
    opacity: 1;
  }

  :global(.MuiButtonBase-root) {
    transition: all 0.3s ease;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
}

.thumbnailCanvas {
  --wysiwyg-base-font-size: var(--thumbnail-font-size);

  position: relative;
  height: var(--thumbnail-height);
  margin: var(--thumbnail-padding);
  font-family: var(--canvas-font-family);
  font-size: var(--thumbnail-font-size);

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    inset: calc(var(--thumbnail-padding) * -1);
    cursor: pointer;
  }
}

.thumbnailBlock {
  position: absolute;
  padding: var(--thumbnail-gutter);
  pointer-events: none;

  > div {
    overflow: hidden;
    border-radius: 7.2em;
  }
}
