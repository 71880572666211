@import 'constants.module';
@import 'variables';
@import 'grid';

body {
  margin: 0;
  background: #f7f7f7;
  color: var(--body-color);
  font: 1rem/1.3 var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='number'] {
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
}

.text-capitalize {
  text-transform: capitalize;
}

.spacer {
  flex-grow: 1;
}

.content {
  max-width: 1324px;
  padding: 30px;
}

main > .MuiContainer-root {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.handle {
  cursor: grab !important;
}

.has-loader {
  position: relative;

  > * {
    transition: filter 0.2s ease;
  }
}

.loading {
  position: relative;
  pointer-events: none;
  user-select: none;

  > * {
    filter: blur(2px);
    transition: all 0.2s ease;
  }
}

div.box-loading {
  --size: 22px;
  --color: 53 55 56;

  cursor: default !important;
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    z-index: 10;
    inset: 0;
    background: rgb(255 255 255 / 60%);
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    width: var(--size);
    height: var(--size);
    margin: calc(var(--size) / 2 * -1) 0 0 calc(var(--size) / 2 * -1);
    animation: spinner-grow 0.75s linear infinite;
    border-radius: 50%;
    opacity: 0;
    background: rgb(var(--color));
  }

  .draggable-container > div {
    color: #999;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    transform: none;
    opacity: 1;
  }
}

.pages-holder {
  display: block;
  text-align: right;

  // width: 60px;
}

.route-text {
  font-size: 27.5em;
  line-height: 1;
  text-align: center;

  .without-icons & {
    font-size: 34em;
  }
}

.time-text {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  color: #000;
  line-height: 0.8;

  &__number {
    font-size: 18.5em;
  }

  &__unit {
    font-size: 10em;

    &.empty {
      font-size: 15em;
    }

    &:last-child {
      margin-left: 0.27em;
    }

    &:first-child:last-child {
      margin-left: 0;
    }
  }
}

.icons-holder {
  display: flex;
  justify-content: center;
  pointer-events: none;

  img {
    width: auto;
    max-width: 10em;
    height: auto;
    max-height: 9em;
  }
}

.message-text {
  font-size: 5em;
  line-height: 1.3;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.timer-control {
  &-group {
    display: flex;

    &:has(.MuiFormHelperText-root) {
      padding-bottom: 22px;
    }

    .MuiInputBase-input:not([disabled]) {
      cursor: pointer;
    }

    .MuiInputBase-root {
      cursor: pointer;
    }

    .MuiFormHelperText-root {
      margin: 2px 0 -22px;
    }
  }

  &-holder {
    flex: 1;
  }
}

.templateTopPanel-root {
  .MuiFormControl-root {
    margin-top: -12px;

    label + .MuiInput-root {
      margin-top: 12px;
    }

    & .MuiFormHelperText-root {
      position: absolute;
      top: 100%;
      margin: 0;
    }
  }
}

.btn-info {
  &.MuiIconButton-root {
    color: var(--info);

    &:hover {
      background-color: rgb(var(--info-rgb) / 4%);
    }
  }
}

.table-md {
  td,
  th {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
}

.MuiTableBody-root {
  .MuiTableRow-root {
    transition: all 0.3s ease;

    &:last-child td,
    &:last-child th {
      border: 0;
    }
  }

  .MuiTableRow-hover {
    &:hover {
      cursor: pointer;
    }
  }
}

.pagination {
  margin: 0 1em;

  &-list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      width: 4.2em;
      margin: 0 0.8em;
      transition: all 0.3s ease;
      border-radius: 50%;
      opacity: 0.3;
      background: currentColor;
      aspect-ratio: 1 / 1;

      &.active {
        opacity: 1;
      }
    }
  }

  &-text {
    font-size: 4em;
    font-weight: 700;
    line-height: 1;
  }
}

.deployed-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  padding: 0.125rem 0.375rem;
  border: 1px solid;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.2143;
  text-transform: uppercase;
  gap: 0.25rem;

  .MuiSvgIcon-root {
    color: var(--danger);
    font-size: 1.25rem;
  }

  &-holder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
  }
}

.modal-top {
  .MuiDialog-container {
    align-items: flex-start;
  }
}

.mce-content-body {
  z-index: 2;
  margin: 0 -1px;
  padding: 0 1px;
  font-size: var(--canvas-default-font-size);

  p {
    margin: 0;
  }
}

.tox-tinymce-inline {
  z-index: 100;
}

.tox {
  .tox-menu {
    padding: 0.25rem;
    border-radius: 0.25rem;
  }

  .tox-swatch[role='menuitemradio'] {
    margin: 0.125rem;
    border-radius: 0.25rem;
    outline: 1px solid rgb(0 0 0 / 25%);
    outline-offset: -1px;
    box-shadow: none !important;
    cursor: pointer;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }

  .tox-tbtn--select[aria-label='Font sizes'] {
    max-width: 70px;
  }
}

.custom-toolbar {
  // visibility: hidden;
  position: absolute;
  z-index: 5;
  bottom: 100%;
  width: 530px;
  border: 2px solid #eee;
  border-radius: 10px;

  // opacity: 0;
  background-color: #fff;
  box-shadow: none;

  &.editing {
    visibility: visible;
    opacity: 1;
  }

  .tox.tox-tinymce-inline .tox-editor-header {
    border: 0;
    background: none;
  }

  &-line {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 0 0.75rem;
  }
}

.wysiwyg-holder {
  position: relative;
}

$font-size: $canvas-default-font-value;
$size-step: 1;

@for $i from $font-size through 20 {
  [style*=' #{$font-size}rem'] {
    font-size: calc(var(--wysiwyg-base-font-size) * #{$font-size}) !important;
  }

  $font-size: $font-size + $size-step;
}

.test {
  display: flex;
  align-items: center;
  padding: 0.375rem;
  gap: 0.375rem;

  .tox .tox-tbtn {
    margin: 0;
  }
}
