@import 'src/components/Canvas/canvas.module';

.configuratorContainer {
  display: flex;
  flex: 1;
  flex-direction: column;

  .LayoutLogoBlock {
    margin-top: 0;
    margin-bottom: 0;

    &.panelBlock {
      &::before,
      &::after {
        inset: calc(var(--canvas-header-panel-height) * var(--padding-y) * -1) -0.5rem 0;
      }
    }

    .panelBlockActions {
      top: calc(var(--canvas-header-panel-height) * var(--padding-y) * -1);
    }
  }
}

.configuratorBar {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 6%), 0 2px 6px 0 rgb(0 0 0 / 6%);
}

.configuratorHolder {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.panelHeaderHolder {
  display: flex;
  position: relative;
  justify-content: inherit;
  width: 100%;
  height: 100%;
}

.panelActions {
  visibility: hidden;
  position: absolute;
  z-index: 5;
  top: 100%;
  right: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease;
  opacity: 0;
  color: #000;
  font-size: 2rem;
  text-align: center;

  .panelHeader:hover &:not(.hide) {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }

  .panelFooter & {
    top: auto;
    bottom: 100%;
  }

  .panelFooter:hover &:not(.hide) {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }

  :global(.MuiSvgIcon-root) {
    font-size: 2rem;
  }
}

.panelBlock {
  display: flex;
  position: relative;
  align-items: center;
  margin: calc(var(--canvas-header-panel-height) * var(--padding-y) * -1) 0.35em;
  font-size: 4em;
  font-weight: 700;
  line-height: 1.1;
  text-transform: capitalize;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
    text-align: right;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    inset: 0 -0.5rem calc(var(--canvas-header-panel-height) * var(--padding-y));
  }

  &::after {
    border: 2px solid var(--primary);
    opacity: 0;
  }

  &:hover {
    &::after,
    .panelBlockActions {
      opacity: 1;
    }
  }
}

:global(.on-choose > div:not(.sortable-chosen)) {
  &::before,
  &::after,
  .panelBlockActions {
    opacity: 0;
  }
}

.panelBlockActions {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 0;
  left: -0.5rem;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  background: var(--primary);
  color: #fff;

  button {
    padding: 2px 3px;
    transition: all 0.3s ease;
    opacity: 0.7;

    svg {
      font-size: 1.25rem;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.configuratorPopup {
  visibility: hidden;
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  width: 450px;
  padding: 1rem;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  opacity: 0;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 6%), 0 2px 6px 0 rgb(0 0 0 / 6%);
  color: var(--body-color);
  font-family: var(--font-family);
  font-size: 1rem;
  text-align: left;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .panelFooter .panelBlock & {
    top: auto;
    bottom: 100%;
  }
}

.configuratorPopupHeader {
  display: flex;
  align-items: center;
  margin: 0 0 1rem;

  p {
    flex: 1;
    padding: 0 0.5rem 0 0;
    font-weight: 700;
  }
}

.configuratorPopupFooter {
  display: flex;
  justify-content: flex-end;
  margin: 1.25rem 0 0;
}

.blocksDropLists {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.blocksDropItem {
  padding: 0.5rem;
  border: 1px solid #bbb;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    border-color: #999;
  }

  &.active {
    border-color: var(--primary);
    background: rgb(104 62 232 / 10%);
    color: var(--primary);
  }

  &.disabled,
  &.reachedLimit {
    opacity: 0.5;
    background: #eee;
    cursor: default;
    pointer-events: none;
  }
}
