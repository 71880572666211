.header {
  display: flex;
  position: relative;
  z-index: 10;
  align-items: center;
  height: var(--header-height);
  padding: 0 1rem;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 6%), 0 2px 6px 0 rgb(0 0 0 / 6%);
}

.logo {
  width: 150px;
  margin: 0 1rem 0 0;

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

.profileDrop {
  padding: 0.5rem 1rem 1rem;
  text-align: center;

  :global(.MuiButton-root) {
    margin: 1rem 0 0;
    color: #888;
  }
}

.nav {
  display: flex;
  flex: 1;
  justify-content: center;
  height: 100%;
  font-size: 1rem;
  font-weight: 700;

  a {
    display: flex;
    position: relative;
    align-items: center;
    padding: 1rem 1.5rem;
    transition: all 0.3s ease;
    color: #777;
    text-decoration: none;
    text-transform: capitalize;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 3px;
      transition: all 0.3s ease;
      opacity: 0;
      background: var(--primary);
    }

    &:hover {
      color: #555;
    }

    &:global(.active) {
      color: var(--primary);

      &::after {
        opacity: 1;
      }
    }
  }

  [data-disabled] {
    opacity: 0.38;
    cursor: default;
    pointer-events: none;
  }
}
