.simpleFrameTitle {
  display: block;
}

.simpleFramePure {
  flex-direction: column;
  flex-grow: 1;
  composes: baseFrame from '/src/assets/styles/base-styles.scss';

  & + & {
    border-left: 1px solid var(--frame-border-color);
  }

  :global([data-grid='rows']) & {
    & + .simpleFramePure {
      border: 0;
      border-top: 1px solid var(--frame-border-color);
    }
  }

  .simpleFrameTitle {
    width: 100%;
    padding: 0.4167em;
    border-bottom: 1px solid var(--frame-border-color);
    font-size: 12px;
    text-align: left;
  }

  .simpleFrameContent {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.4167em;
  }
}

.simpleConnectContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0.5em;
  gap: 0.5em;
}

.connectBlock {
  display: flex;
  align-items: center;
  width: 100%;

  span {
    min-width: 2em;
    padding: 0 0.5em 0 0;
  }

  :global(.icons-holder) {
    width: 6.75em;
    margin: 0 4em 0 0;

    img {
      max-width: 100%;
    }
  }
}

.connectBlockText {
  font-size: 6em;
}

.simpleFrame {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
  overflow: hidden;

  &:has(:global(.mce-edit-focus)) {
    overflow: visible;
  }

  .simpleFrameTitle {
    width: 100%;
    overflow: hidden;
    font-size: 6.5em;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .simpleFrameContent {
    min-height: 0;

    &:first-child {
      :global {
        .route-text {
          font-size: 34em;
        }
      }
    }
  }
}

.simpleFrameAlt {
  gap: 3em;
}

.staticTextFrame {
  flex-direction: row;

  .simpleFrameContent {
    width: 100%;
  }
}

.staticText {
  display: block;
  width: 100%;
  font-size: var(--canvas-default-font-size);

  :global {
    p {
      margin: 0;
    }
  }
}

.iconsFrame {
  composes: baseFrame from '/src/assets/styles/base-styles.scss';
  justify-content: center;
  text-align: center;

  :global(.row-3) & {
    font-size: 0.85em;
  }
}

.iconsBoxPure {
  display: flex;
  flex: 1;
  height: 100%;

  & + & {
    border-left: 1px solid var(--frame-border-color);
  }

  :global([data-grid='rows']) & {
    & + .iconsBoxPure {
      border: 0;
      border-top: 1px solid var(--frame-border-color);
    }
  }

  .iconsHolder {
    display: flex;
    flex-direction: column;
    width: 33%;
    border-left: 1px solid var(--frame-border-color);
  }

  .iconsFrame {
    flex: 1;

    + .iconsFrame {
      border-top: 1px solid var(--frame-border-color);
    }
  }

  &.directionColumn {
    flex-direction: column;

    .iconsHolder {
      flex: 1;
      flex-direction: row;
      width: 100%;
      border: 0;
      border-top: 1px solid var(--frame-border-color);
    }

    .iconsFrame + .iconsFrame {
      border: 0;
      border-left: 1px solid var(--frame-border-color);
    }
  }
}

.iconsBox {
  display: flex;
  position: relative;
  flex: 1;
  min-width: 0;
  height: 100%;

  .iconsHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12%;
    width: 12em;

    &:empty {
      display: none;
    }
  }

  &.directionColumn {
    flex-direction: column;
    gap: 4%;

    .iconsContent {
      display: flex;
      flex: 1;
      align-items: flex-end;

      &:first-child:last-child {
        align-items: center;
      }
    }

    .iconsHolder {
      flex: 1;
      flex-direction: row;
      width: 100%;
      padding: 0;
      gap: 3.6em;
    }

    :global {
      .time-text__number {
        font-size: 25em;
      }

      .icons-holder {
        img {
          max-width: none;
          height: 11.5em;
          max-height: none;
        }
      }
    }
  }
}

.iconsContent {
  align-self: center;
  margin: 0 auto;
  text-align: center;
}

.variableText {
  display: block;
  font-size: 6em;
  text-align: center;

  :global(.icons-holder) & {
    font-size: 4em;
  }
}

.imageFrame {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 0;
  height: 100%;
  font-size: 5em;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
}

.imageFrameThumbnail {
  :global(.MuiSvgIcon-root) {
    color: #999;
    font-size: 2.5em;
  }
}
