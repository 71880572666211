.logoActions {
  display: flex;
  position: absolute;
  bottom: 100%;
  left: -0.25rem;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0.25rem;
  transition: all 0.3s ease;
  border-radius: 4px;
  opacity: 0;
  background: #fff;
  box-shadow: 2px -2px 5px rgb(0 0 0 / 15%);

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    width: 100%;
    height: 0.5rem;
  }

  button + button {
    margin-left: 0.25rem;
  }
}

.logoBlock {
  display: inline-flex;
  position: relative;
  flex: 1;
  align-items: center;
  height: 100%;
  text-align: center;
  justify-self: flex-start;

  :global(.MuiButton-root) {
    padding: 0;
    transition: all 0.3s ease;
    opacity: 0.6;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.5;

    &:hover {
      opacity: 1;
      background: none;
    }
  }

  :global(.MuiButton-root .MuiSvgIcon-root) {
    font-size: 1.25em;
  }

  :global(.footer-right) & {
    justify-content: flex-end;
  }

  &:hover .logoActions {
    margin: 0.5rem 0;
    opacity: 1;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

.logoBlockText {
  opacity: 0.5;
}
