.dragBlock {
  margin: 0 0 1rem;

  &:last-child {
    margin: 0;
  }

  &:empty {
    display: none;
  }
}
