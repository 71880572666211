.baseFrame {
  display: flex;
  align-items: center;
  min-height: 25%;
  background: var(--frame-background);
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.baseTemplateAside {
  display: flex;
  position: sticky;
  top: 0;
  flex-shrink: 0;
  width: 251px;
  height: 100%;
  overflow-y: auto;
  background: #fff;
}

.baseScrollBlock {
  --padding: 1.25rem;

  flex-grow: 1;
  padding: 1rem var(--padding);
  overflow-x: hidden;
  overflow-y: auto;
  background: #fdfdfd;

  @supports (scrollbar-gutter: stable) {
    scrollbar-gutter: stable;
    padding-right: calc(var(--padding) - var(--scrollbar-width));
  }

  /* width */
  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: none;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    transition: all 0.2s ease;
    border: 4px solid transparent;
    background: #ccc;
    background-clip: content-box;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
}
