:root {
  --padding-x: 0.24409;
  --padding-y: 0.12;
}

.container {
  display: flex;
  flex-direction: column;
  width: var(--canvas-outer-width);
  background-color: #d8d8d8;
  box-shadow: 0 0 0 1px #ddd;
  font-family: var(--canvas-font-family);
  font-size: var(--canvas-font-size);
  aspect-ratio: 1 / var(--canvas-ratio);
}

.content {
  position: relative;
  flex-grow: 1;
  margin: var(--canvas-padding);
  user-select: none;

  .contentStripe {
    position: absolute;
    top: calc(var(--canvas-padding) * -1);
    right: 0;
    bottom: calc(var(--canvas-padding) * -1);
    left: 0;
    background-image: linear-gradient(
      to right,
      transparent var(--canvas-gutter),
      rgb(0 0 0 / 5%) var(--canvas-gutter),
      rgb(0 0 0 / 5%) calc(var(--canvas-col-width) - var(--canvas-gutter)),
      transparent 1px
    );
    background-size: var(--canvas-col-width) var(--canvas-row-height);
  }

  &::after,
  &::before {
    content: '';
    visibility: hidden;
    position: absolute;
    z-index: 3;
    inset: calc(var(--canvas-padding) * -1 + 2px);
    transition: all 0.3s ease;
    border: 3px dashed var(--primary);
    border-radius: 4px;
    opacity: 0;
    background: rgb(104 62 232 / 5%);
  }

  &::after {
    border-style: solid;
  }
}

.isDrag {
  &::before {
    visibility: visible;
    opacity: 1;
  }
}

.dragOver {
  &::after {
    visibility: visible;
    opacity: 1;
  }
}

.panelHeader {
  display: flex;
  position: relative;
  align-items: center;
  height: var(--canvas-header-panel-height);
  padding: calc(var(--canvas-header-panel-height) * var(--padding-y)) calc(var(--canvas-header-panel-height) * 0.338582);
  background: #000;
  gap: 0 3%;
}

.footerBlock {
  font-size: 8em;
  font-weight: 700;
  line-height: 0.77941;
}

.panelFooter {
  display: flex;
  position: relative;
  align-items: center;
  height: var(--canvas-footer-panel-height);
  margin-top: auto;
  padding: calc(var(--canvas-footer-panel-height) * var(--padding-y))
    calc(var(--canvas-footer-panel-height) * var(--padding-x));
  color: #000;

  .footerBlock {
    flex: 1;
    height: 100%;

    &:last-child {
      text-align: right;
    }
  }
}

.panelFooterHolder {
  display: grid;
  grid-auto-rows: calc(var(--canvas-footer-panel-height) * (1 - var(--padding-y) * 2));
  grid-template-areas: 'footer-left pagination footer-right';
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
  height: 100%;
  gap: 2rem;

  :global(.pagination) {
    grid-area: pagination;
    align-self: center;
  }

  :global(.footer-right) {
    grid-area: footer-right;
    justify-content: flex-end;
    text-align: right;

    .errorMark {
      right: -12px;
      left: auto;
    }
  }
}

.panelBlock {
  position: relative;
  flex-shrink: 0;
  margin: auto 0;
  font-size: 4em;
  font-weight: 700;
  line-height: 1.1;
  text-transform: capitalize;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .panelFooter & {
    margin-right: 0;
    margin-left: 0;
  }

  .panelFooter :global(.footer-right) + & {
    text-align: left;
  }

  &.panelBlockPreview {
    text-transform: none;

    &:has(div:empty) {
      display: none;
    }
  }
}

.LayoutCustomTextBlock {
  flex-shrink: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.LayoutLogoBlock {
  height: 100%;
}

.panelFooter .LayoutTimeBlock {
  // font-size: 8em;
  // font-weight: 700;
  // line-height: 0.77941;
}

.panelBlockActions {
  display: flex;
  position: absolute;
  z-index: 2;
  top: -1.5rem;
  left: -0.5rem;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  background: var(--primary);
  color: #fff;

  button {
    padding: 2px 3px;
    transition: all 0.3s ease;
    opacity: 0.7;

    svg {
      font-size: 1.25rem;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.errorMark {
  position: absolute;
  z-index: 10;
  left: -12px;
  width: 7px;
  border-radius: 50%;
  background: #f00;
  box-shadow: 0 0 0 1px #fff;
  aspect-ratio: 1 / 1;
}

.draggableHolder {
  position: relative;
  height: 100%;

  // overflow: hidden;
  transition: border-radius 0.2s ease;
  border-radius: var(--block-border-radius);
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);

  &:hover {
    border-top-right-radius: 3em;
  }

  &:global(.dark-mode) {
    box-shadow: 0 0 5px 1px rgb(255 255 255 / 30%);
  }

  :global(.read-only) & {
    overflow: hidden;
    border-radius: var(--block-border-radius) !important;
    box-shadow: none !important;
    cursor: default !important;
  }

  :global(.react-draggable-resizing) & {
    &:hover {
      border-top-right-radius: var(--block-border-radius);

      .btnBlockRemove {
        display: none;
      }
    }
  }
}

button.btnBlockSettings,
button.btnBlockRemove {
  position: absolute;
  z-index: 2;
  width: 7em;
  height: 7em;
  padding: 0;
  transition: all 0.2s ease;
  border-radius: 3em;
  opacity: 0;
  background: none;
  box-shadow: none;
  color: #333;
  font-size: inherit;
  pointer-events: auto;

  :global(.MuiSvgIcon-root) {
    font-size: 4.5em;
  }

  .draggableHolder:hover & {
    margin: 0;
    opacity: 0.5;
    background: none;

    &:hover {
      opacity: 0.8;
    }
  }

  :global(.dark-mode) & {
    color: #fff;
  }
}

button.btnBlockRemove {
  top: 0;
  right: 0;
  margin: 1em 1em 0 0;
}

button.btnBlockSettings {
  top: 0;
  left: 0;
  margin: 1em 0 0 1em;
}

.draggableHolderText:hover {
  border-top-left-radius: 3em;
}

.draggableHolderImage {
  --block-border-radius: 3em;
}

.draggableHolderImageError {
  :global(.draggable-container > div) {
    background: #ffd6d6;
    color: var(--danger);
  }
}

:global {
  .MuiColorInput-TextField .MuiInputAdornment-root .MuiTouchRipple-root {
    box-shadow: 0 0 5px rgb(0 0 0 / 33%);
  }

  .MuiSlider-rail {
    box-shadow: 0 0 8px rgb(0 0 0 / 25%);
  }

  .handle-resize {
    z-index: 3;
    transition: all 0.3s ease;
    opacity: 0;
    color: #555;
    pointer-events: auto;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40%;
      max-width: 200px;
      height: 30%;
      transform: translate(-50%, -50%);
      border-radius: 50px;
      background: currentColor;
    }
  }

  .handle-resize-x {
    transition: all 0.3s ease;
    opacity: 0;
    color: #555;
    pointer-events: auto;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 35%;
      height: 40%;
      max-height: 200px;
      transform: translate(-50%, -50%);
      border-radius: 50px;
      background: currentColor;
    }
  }

  .react-draggable {
    z-index: 2;
    padding: var(--canvas-gutter);
    transition: none;
    pointer-events: none;

    &:hover {
      z-index: 99;
      transition: z-index 0s ease 0.3s;

      .handle-resize-x,
      .handle-resize {
        opacity: 0.3;

        &:hover {
          opacity: 0.55;
        }
      }
    }

    &.active {
      z-index: 100;
    }
  }

  .draggable-container {
    height: 100%;
  }

  .draggable-container-handler {
    position: absolute;
    cursor: grab;
    pointer-events: auto;
    inset: 0;

    &:active {
      cursor: grabbing;
    }
  }

  .react-draggable-resizing {
    cursor: row-resize;

    * {
      cursor: row-resize;
    }

    .react-draggable .handle-resize {
      opacity: 0;
    }
  }
}

.templateBlockPanel {
  position: absolute;
  z-index: 3;
  top: 1.5em;
  right: 7.5em;
  color: #aaa;
  font-family: var(--font-family);

  span {
    font-size: 4em;
    line-height: 1;
  }
}

.draggableHolderEta {
  border-top-left-radius: 4em;
}

.templateBlockInfo {
  display: flex;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  align-items: center;
  pointer-events: auto;

  :global {
    .MuiInput-root {
      width: 6em;
      margin: 0.5em 0 0 1em;
      transition: all 0.3s ease;
      opacity: 0.5;
      background: #fff;
      font-size: 1rem;
      font-size: max(3em, 10px);
    }

    .Mui-focused,
    .Mui-error {
      opacity: 1;
    }

    .Mui-error {
      background: #ffd6d6;
    }
  }

  &:hover {
    :global(.MuiInput-root) {
      opacity: 1;
    }
  }
}

.templateBlockInfoText {
  display: flex;
  align-items: center;
  padding: 0.5em 0 0 1.25em;
  color: #777;
  font-family: var(--font-family);
  font-size: 3em;
  text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;
  gap: 0.25rem;
}

.wideCanvas {
  --block-border-radius: 4.2em;

  :global {
    .route-text {
      display: block;
      line-height: 0.85;
    }
  }
}
