.paginationBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 2rem 0 0;
  color: #777;

  :global(.MuiGrid2-root) > & {
    margin-top: 0;
  }

  > * {
    margin-left: 1.5rem;
  }
}

.rowsSelector {
  min-width: 60px;

  > div:focus {
    background: none;
  }
}
