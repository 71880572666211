:root {
  --font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  --canvas-full-width: 1600;
  --canvas-outer-width: 864px;
  --canvas-padding-percent: 0.01875;
  --canvas-padding: calc(var(--canvas-outer-width) * var(--canvas-padding-percent));
  --canvas-width: calc(var(--canvas-outer-width) - var(--canvas-padding) * 2);
  --canvas-scale: calc(var(--canvas-width) / var(--canvas-full-width));
  --canvas-base-font-size: 10;
  --canvas-font-size: calc(var(--canvas-scale) * var(--canvas-base-font-size));
  --canvas-cols: 12;
  --canvas-rows: 12;
  --canvas-ratio: 0.75;
  --canvas-col-width: calc(var(--canvas-width) / var(--canvas-cols));
  --canvas-outer-height: var(--canvas-width) * var(--canvas-ratio);
  --canvas-footer-panel-height: calc(var(--canvas-outer-height) * 0.10582333);
  --canvas-header-panel-height: calc(var(--canvas-outer-height) * 0.10582333);
  --canvas-height: calc(
    var(--canvas-outer-height) - var(--canvas-footer-panel-height) - var(--canvas-header-panel-height) -
      var(--canvas-padding) * 2
  );

  // --canvas-panel-content-height: calc(var(--canvas-panel-bottom-height) * 0.417322);
  --canvas-row-height: calc(var(--canvas-height) / var(--canvas-rows));
  --scrollbar-width: 12px;
  --frame-border-color: #bbb;
  --frame-background: #f7f7f7;
  --block-gap: 0.5em;
  --canvas-gutter-percent: 0.010625;
  --canvas-gutter: calc(var(--canvas-outer-width) * var(--canvas-gutter-percent));
  --aside-background: #eee;
  --aside-border-color: #ccc;
  --header-height: 68px;
  --primary: #683ee8;
  --danger: #d32f2f;
  --info: #0288d1;
  --info-rgb: 2 136 209;
  --thumbnail-width: 210px;
  --thumbnail-ratio: 0.59125;
  --thumbnail-padding: calc(var(--thumbnail-width) * var(--canvas-padding-percent));
  --thumbnail-height: calc(var(--thumbnail-width) * var(--thumbnail-ratio) - var(--thumbnail-padding) * 2);
  --thumbnail-font-size: calc(var(--thumbnail-width) / var(--canvas-full-width) * var(--canvas-base-font-size));
  --thumbnail-gutter: calc(var(--thumbnail-width) * var(--canvas-gutter-percent));
  --body-color: #333;
  --block-border-radius: 7.2em;
  --canvas-font-family: 'Times New Roman', Times, serif;
  --canvas-default-font-value: #{$canvas-default-font-value};
  --canvas-default-font-size: calc(var(--canvas-default-font-value) * 1em);
  --wysiwyg-base-font-size: var(--canvas-font-size);
}
