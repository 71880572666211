.carouselModal {
  display: flex;
  position: fixed;
  z-index: 50;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  outline: none;
  background: #f7f7f7;
}

.carouselModalControls {
  display: flex;
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 0;
  flex-direction: column;
  padding: 10px;
  transition: all 0.3s ease;
  border-radius: 0 0.5rem 0.5rem 0;
  background: #fff;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 30%);
  translate: 0 -50%;
}

.carouselModalContent {
  display: flex;
  flex-direction: column;
  width: var(--canvas-outer-width);
  margin: auto;
  background-color: #d8d8d8;
  box-shadow: 0 0 0 1px #ddd;
  font-size: var(--canvas-font-size);
  aspect-ratio: 1 / var(--canvas-ratio);
}

.carouselProgress {
  position: fixed !important;
  z-index: 10 !important;
  top: 0;
  right: 0;
  left: 0;
}
