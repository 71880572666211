.templateAside {
  composes: baseTemplateAside from '/src/assets/styles/base-styles.scss';
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 4%), 4px 12px 22px -8px rgb(0 0 0 / 6%);

  :global(.MuiButtonBase-root) {
    flex: 1;
  }

  :global(.MuiTabs-root) {
    border-bottom: 1px solid #ccc;
  }
}

.scrollBlock {
  composes: baseScrollBlock from '/src/assets/styles/base-styles.scss';
}
