.col-12 {
  width: 100%;
}

.col-11 {
  width: 91.66666667%;
}

.col-10 {
  width: 83.33333333%;
}

.col-9 {
  width: 75%;
}

.col-8 {
  width: 66.66666667%;
}

.col-7 {
  width: 58.33333333%;
}

.col-6 {
  width: 50%;
}

.col-5 {
  width: 41.66666667%;
}

.col-4 {
  width: 33.33333333%;
}

.col-3 {
  width: 25%;
}

.col-2 {
  width: 16.66666667%;
}

.col-1 {
  width: 8.33333333%;
}

.row-12 {
  height: 100%;
}

.row-11 {
  height: 91.66666667%;
}

.row-10 {
  height: 83.33333333%;
}

.row-9 {
  height: 75%;
}

.row-8 {
  height: 66.66666667%;
}

.row-7 {
  height: 58.33333333%;
}

.row-6 {
  height: 50%;
}

.row-5 {
  height: 41.66666667%;
}

.row-4 {
  height: 33.33333333%;
}

.row-3 {
  height: 25%;
}

.row-2 {
  height: 16.66666667%;
}

.row-1 {
  height: 8.33333333%;
}
