.templateTopPanel {
  display: flex;
  z-index: 3;
  top: 0;
  align-items: center;
  padding: 1rem;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 6%), 0 2px 6px 0 rgb(0 0 0 / 6%);
  gap: 1rem;
}

.templateName {
  margin: 0;
}

.delayBlock {
  display: flex;
  align-items: baseline;
  margin: 0 0 0 0.5rem;
  color: #777;

  strong {
    margin: 0 0.125rem 0 0.25rem;
    color: #333;
    font-weight: 400;
  }
}

.fakeTextField {
  position: relative;
  margin-left: 1rem;
  color: #777;

  &:last-child {
    margin-right: 1rem;
  }

  label {
    display: block;
    opacity: 0.6;
    color: #000;
    font-size: 0.875rem;
    line-height: 1.32;
  }

  strong {
    margin: 0 0.125rem 0 0;
    color: #333;
    font-weight: 400;
  }
}
