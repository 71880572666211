.status {
  display: flex;
  align-items: center;
  color: #555;
  line-height: 1.2857;
  gap: 0.25rem;

  :global {
    .MuiIconButton-root {
      color: var(--info);

      &:hover {
        background-color: rgb(var(--info-rgb) / 4%);
      }
    }
  }
}

.statusText {
  display: flex;
  align-items: center;
}

.statusSecondary {
  padding-left: 1rem;
  opacity: 0.6;
  color: #000;
}

.statusMark {
  flex-shrink: 0;
  width: 0.5rem;
  margin: 0 0.5rem 0 0;
  border-radius: 50%;
  background: currentColor;
  aspect-ratio: 1 / 1;
}
