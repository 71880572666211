.languages {
  display: flex;
  align-items: center;

  span {
    position: relative;
    margin: 0 1rem;
    transition: all 0.3s ease;
    color: #999;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: #555;
    }

    + span::before {
      content: '';
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: -1rem;
      width: 1px;
      outline: none;
      background: currentColor;
      pointer-events: none;
    }
  }

  .active {
    color: var(--primary);
  }
}
