.templateContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.templateContent {
  display: flex;
  position: relative;
  flex: 1;
  min-height: 0;
}

.templateHolder {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}
