.templateBlockPure {
  display: flex;
  position: relative;
  height: auto;
  padding: 0;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  border: 1px solid var(--frame-border-color);
  border-radius: 4px;
  background: var(--frame-background);
  cursor: grab;
  user-select: none;
  aspect-ratio: 1 / 0.45;

  &:active {
    cursor: grabbing !important;
  }

  :global(.read-only) & {
    box-shadow: none !important;
    cursor: default !important;
  }

  &::after {
    content: '';
    display: none;
    position: absolute;
    transition: all 0.3s ease;
    border: 1px solid var(--frame-border-color);
    border-radius: 4px;
    inset: 0;
  }

  &:hover::after {
    border-color: #999;
  }

  :global([data-height='sm']) & {
    aspect-ratio: 1 / 0.3;
  }

  :global([data-height='xs']) & {
    aspect-ratio: 1 / 0.2;
  }

  &[data-grid='rows'] {
    flex-direction: column;
  }
}

.templateBlock {
  height: 100%;
  padding: 2em 4em;
  background: #fff;
  color: #000;
  pointer-events: auto;
  border-radius: inherit;

  &:global {
    &[data-grid='rows'],
    &[data-grid='cols'] {
      display: flex;
      position: relative;
      gap: 8em;

      &::before {
        content: '';
        position: absolute;
        top: 4em;
        bottom: 4em;
        left: 50%;
        width: 0.5em;
        background: #b3b3b3;
      }
    }

    &[data-grid='rows'] {
      flex-direction: column;
      gap: 4em;

      &::before {
        top: 50%;
        right: 4em;
        bottom: auto;
        left: 4em;
        width: auto;
        height: 0.5em;
      }

      .icons-holder img {
        max-height: 6.5em;
      }
    }
  }

  &.inversionView {
    filter: invert(100%);
  }
}

.thumbnailImageBlock {
  border-radius: 3em !important;
}
