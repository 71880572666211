@import './src/pages/TransitTemplate/transit-template.module';
@import './src/pages/TransitTemplate/components/TemplateAsideBlocks/template-aside-blocks.module';

.templateAsideStops {
  padding: 1rem;

  :global {
    .MuiList-root {
      padding: 0;
      font-size: 0.875rem;
    }

    .MuiListItem-root:not(:first-child) {
      border-top: 1px solid rgb(224 224 224);
    }
  }

  .scrollBlock {
    --padding: 1rem;

    margin: 0 calc(var(--padding) * -1) calc(var(--padding) * -1);
    padding-top: 0;
    padding-bottom: 0;
  }
}

.stopsTable {
  margin: 0 calc(var(--padding) * -1);

  th,
  td {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
