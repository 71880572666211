.pageSpinnerBackdrop {
  visibility: hidden;
  position: fixed;
  z-index: 1400;
  transition: all 0.3s ease;
  opacity: 0;
  inset: 0;
  background: rgb(255 255 255 / 50%);

  &.show {
    visibility: visible;
    opacity: 1;
  }
}

.pageSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1rem 0 0 -1rem;
  filter: blur(0);
}
