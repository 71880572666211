.templateAside {
  composes: baseTemplateAside from '/src/assets/styles/base-styles.scss';
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ddd;
  box-shadow: -1px 2px 2px rgb(0 0 0 / 4%), -4px 12px 22px -8px rgb(0 0 0 / 6%);

  :global(.MuiTabs-root) {
    border-bottom: 1px solid #ccc;
  }

  :global(.MuiTabs-root .MuiButtonBase-root) {
    flex: 1;
  }
}

.scrollBlock {
  composes: baseScrollBlock from '/src/assets/styles/base-styles.scss';
}

.scrollBlockActions {
  position: sticky;
  bottom: -1rem;
  margin: 0.5rem -1.25rem -1rem;
  padding: 1rem 1.25rem;
  background: #fdfdfd;
  box-shadow: 0 -0.75rem 0.75rem rgb(255 255 255 / 80%);
  text-align: center;
}

.topActions {
  padding: 0.375rem;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.settingsPanel {
  position: absolute;
  position: fixed;
  z-index: 10;

  // inset: 0;
  top: 200px;
  right: 0;
  bottom: 0;
  width: 800px;
}
